import fileQueryPartial from '@/queries/fileQueryPartial';
import uploadFileQueryPartial from '@/queries/uploadFileQueryPartial';
export default i18n => {
  const lang = i18n.locale;
  return `
      title_${lang}
      description_${lang}
      files_${lang} {
        ${fileQueryPartial}
      }
      image {
        ${uploadFileQueryPartial}
      }
      id
  `;
};
