<template>
  <component
    :class="$style['strapi-bg-image']"
    :is="tagName"
    :style="{ 'background-image': `url(${computedSrc})` }"
  >
    <slot />
  </component>
</template>

<script>
import mediaBaseMixin from '@/components/media/mediaBaseMixin';
import containerBaseMixin from '@/components/containers/containerBaseMixin';

export default {
  name: 'MediaBgImage',
  mixins: [mediaBaseMixin, containerBaseMixin]
};
</script>

<style module>
.strapi-bg-image {
  background-position: center center;
  background-size: cover;
}
</style>
