import BaseModel from '@/models/BaseModel';
import Recipe from '@/models/Recipe';

export default class ProductCategory extends BaseModel {
  /**
   * @returns {String}
   */
  get name() {
    return this.localizedGetter('name');
  }
  /**
   * @returns {Array<Recipe>}
   */
  get recipes() {
    return this.objectGetter('recipes', this._object.recipes, Recipe);
  }
}
