<template>
  <ContentContainer :class="$style['product-content']">
    <div :class="$style['product-content__handle-wrapper']">
      <img
        :class="$style['product-content__handle']"
        src="../../../assets/img/global/global__handle.svg"
        alt=""
      />
    </div>
    <h1 :class="$style['product-content__title']">
      {{ product.title }}
    </h1>
    <MarkdownContainer
      :class="$style['product-content__description']"
      :markdown="product.description"
    />
    <div :class="$style['product-content__files']">
      <MediaFileDownload
        v-for="file in product.files"
        :class="$style['product-content__files-file']"
        :key="file.id"
        :type="buttonTypes.FILLED"
        :file="file"
        :strapi="true"
      />
    </div>
    <MediaGalleries :images="product.images" :videos="product.videos" />
  </ContentContainer>
</template>

<script>
import MarkdownContainer from '@/components/containers/MarkdownContainer';
import MediaGalleries from '@/components/media/gallery/MediaGalleries';
import productSectionBaseMixin from '@/views/Product/sections/productSectionBaseMixin';
import MediaFileDownload from '@/components/media/MediaFileDownload';

export default {
  name: 'ProductContent',
  components: {
    MarkdownContainer,
    MediaGalleries,
    MediaFileDownload
  },
  mixins: [productSectionBaseMixin]
};
</script>

<style module>
.product-content {
  background-image: url('~@/assets/img/global/global__background-texture.svg');
  background-position: 0 -25%;
  background-size: 100%;
  @apply bg-black bg-no-repeat
  rounded-tl-3xl rounded-tr-3xl
  mt-50vh
  md:bg-none;
}

.product-content__handle-wrapper {
  @apply flex justify-center mb-6;
}

.product-content__handle {
  @apply w-10;
}

.product-content__title {
  @apply text-white text-4xl mb-4 font-bold;
}

.product-content__files {
  @apply list-none
  flex flex-col items-center justify-center
  mb-8;
}

.product-content__files-file:not(:last-child) {
  @apply mb-4;
}
</style>
