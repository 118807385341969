import Product from '@/models/Product';

export default {
  props: {
    product: {
      type: Product,
      required: true
    }
  }
};
