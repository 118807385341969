<template>
  <div :class="$style['product']">
    <div :class="$style['product__loading']" v-if="$apollo.loading">
      <Loader />
    </div>
    <section v-else>
      <MediaBgImage
        tag-name="header"
        :strapi="true"
        :class="$style['product__header']"
        :src="product.featuredImage.url"
      />
      <main>
        <ProductContent :product="product" />
        <ProductRecipes v-if="showRecipes" :product="product" />
      </main>
    </section>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import productQuery from '@/queries/productQuery';
import Product from '@/models/Product';
import MediaBgImage from '@/components/media/MediaBgImage';
import ProductContent from '@/views/Product/sections/ProductContent';
import ProductRecipes from '@/views/Product/sections/ProductRecipes';
import Loader from '@/components/loader/Loader';

export default {
  name: 'Product',
  components: {
    MediaBgImage,
    ProductContent,
    ProductRecipes,
    Loader
  },
  apollo: {
    products: {
      query() {
        return gql`
          ${productQuery(this.$route.params.productNumber, this.$i18n)}
        `;
      }
    }
  },
  computed: {
    product() {
      if (!this.$apollo.loading && this.products.length > 0) {
        return new Product(this.products[0], this.$i18n.locale);
      } else {
        return null;
      }
    },
    showRecipes() {
      return (
        this.product.recipes.length > 0 ||
        this.product.productCategory.recipes.length > 0
      );
    }
  }
};
</script>

<style module>
.product {
  @apply overflow-hidden;
}

.product__loading {
  @apply bg-black h-screen w-screen flex justify-center items-center;
}

.product__header {
  @apply h-3/5 overflow-hidden fixed -z-1 top-24 w-full overflow-y-hidden;
}

.product__featured-image {
  @apply absolute
  w-full;
}
</style>
