<template>
  <component
    :is="tagName"
    :class="{
      [$style['overlay-container']]: true,
      [$style['overlay-container--no-close']]: !closeButton
    }"
  >
    <div :class="$style['overlay-container__content']">
      <img
        :class="$style['overlay-container__content-background-texture']"
        src="../../assets/img/global/global__background-texture.svg"
        alt=""
      />
      <slot />
    </div>
    <div v-if="closeButton" :class="$style['overlay-container__close-wrapper']">
      <img
        @click="clickCloseOverlay"
        :class="$style['overlay-container__close']"
        src="../../assets/img/global/global__close.svg"
      />
    </div>
  </component>
</template>

<script>
import containerBaseMixin from '@/components/containers/containerBaseMixin';

export default {
  name: 'OverlayContainer',
  props: {
    closeButton: {
      type: Boolean,
      default: true
    }
  },
  mixins: [containerBaseMixin],
  beforeCreate() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = 'scroll';
  },
  methods: {
    clickCloseOverlay() {
      this.$emit('close-overlay');
      if (this.$parent && typeof this.$parent.$emit === 'function') {
        this.$parent.$emit('close-overlay');
      }
    }
  }
};
</script>

<style module>
.overlay-container {
  @apply fixed
  bg-no-repeat
  z-20
  h-screen w-screen
  bottom-0 left-0;
}

.overlay-container__content {
  @apply h-7/8 w-full max-w-2xl my-0 mx-auto;
}

.overlay-container__content-background-texture {
  @apply absolute
  w-full
  -top-32
  opacity-80
  -z-1
  md:hidden;
}

.overlay-container__close-wrapper {
  @apply h-1/8
  flex items-center justify-center;
}

.overlay-container--no-close .overlay-container__content {
  @apply h-full;
}

.overlay-container__close {
  @apply cursor-pointer;
}
</style>
