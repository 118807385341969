/**
 * @param {Array} arr
 * @param {Function} getValue
 * @returns {[]}
 */
export default function uniqBy(arr, getValue) {
  if (!arr || !Array.isArray(arr)) {
    throw new TypeError('param arr must be an Array');
  }
  if (!getValue || typeof getValue !== 'function') {
    throw new TypeError('param fn must be a Function');
  }
  const found = {};
  const unique = [];
  arr.forEach(item => {
    const value = getValue(item);
    if (!found[value]) {
      found[value] = true;
      unique.push(item);
    }
  });
  return unique;
}
