import BaseModel from '@/models/BaseModel';
import File from '@/models/File';
import UploadFile from '@/models/UploadFile';

export default class Recipe extends BaseModel {
  /**
   * @returns {String}
   */
  get title() {
    return this.localizedGetter('title');
  }
  /**
   * @returns {String}
   */
  get description() {
    return this.localizedGetter('description');
  }
  /**
   * @returns {Array<File>}
   */
  get files() {
    return this.objectGetter('files', this.localizedGetter('files'), File);
  }
  /**
   * @returns {UploadFile}
   */
  get image() {
    return this.objectGetter('image', this._object.image, UploadFile);
  }
}
