<template>
  <OverlayContainer
    tag-name="section"
    :class="[$style['recipe-overlay'], $style[`recipe-overlay--${color}`]]"
    :close-button="false"
  >
    <header>
      <ContentContainer :class="$style['recipe-overlay__header-title-wrapper']">
        <MinusButton
          @click.native="clickCloseOverlay"
          :class="$style['recipe-overlay__header-close']"
        />
        <h2 :class="$style['recipe-overlay__header-title']">
          {{ recipe.title }}
        </h2>
      </ContentContainer>
      <MediaBgImage
        :class="$style['recipe-overlay__header-image']"
        :strapi="true"
        :src="recipe.image.url"
      />
    </header>
    <ContentContainer tag-name="main">
      <MarkdownContainer :markdown="recipe.description" />
      <div :class="$style['recipe-overlay__content-files']">
        <MediaFileDownload
          v-for="file in recipe.files"
          :class="$style['recipe-overlay__content-files-file']"
          :key="file.id"
          :type="buttonTypes.OPACITY"
          :file="file"
          :strapi="true"
        />
      </div>
    </ContentContainer>
  </OverlayContainer>
</template>

<script>
import OverlayContainer from '@/components/containers/OverlayContainer';
import Recipe from '@/models/Recipe';
import MinusButton from '@/components/button/MinusButton';
import MediaBgImage from '@/components/media/MediaBgImage';
import MarkdownContainer from '@/components/containers/MarkdownContainer';
import MediaFileDownload from '@/components/media/MediaFileDownload';
export default {
  name: 'RecipeOverlay',
  components: {
    OverlayContainer,
    MinusButton,
    MediaBgImage,
    MarkdownContainer,
    MediaFileDownload
  },
  props: {
    recipe: {
      type: Recipe,
      required: true
    },
    color: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'secondary'].includes(prop)
    }
  },
  methods: {
    clickCloseOverlay() {
      this.$emit('close-overlay');
    }
  }
};
</script>

<style module>
.recipe-overlay {
  @apply overflow-scroll;
}

.recipe-overlay.recipe-overlay--primary {
  @apply bg-primary;
}

.recipe-overlay.recipe-overlay--secondary {
  @apply bg-secondary;
}

.recipe-overlay__header-title-wrapper {
  @apply pt-20 w-4/5 pb-4;
}

.recipe-overlay__header-title {
  @apply text-xl text-white font-bold;
}

.recipe-overlay__header-close {
  @apply p-3
  absolute right-4 top-12;
}

.recipe-overlay__header-image {
  @apply w-full h-40 border-none rounded-tr-3xl rounded-tl-3xl;
}

.recipe-overlay__content-files {
  @apply list-none
  flex flex-col items-center justify-center;
}

.recipe-overlay__content-files-file:not(:last-child) {
  @apply mb-4;
}
</style>
