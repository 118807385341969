<template>
  <VueMarkdown :class="$style['markdown-container']">
    {{ markdown }}
  </VueMarkdown>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import containerBaseMixin from '@/components/containers/containerBaseMixin';
export default {
  name: 'MarkdownContainer',
  mixins: [containerBaseMixin],
  components: {
    VueMarkdown
  },
  props: {
    markdown: {
      type: String,
      required: true
    }
  }
};
</script>

<style module>
.markdown-container {
  @apply text-white text-base mb-8;
}

.markdown-container p:not(:last-child) {
  @apply mb-4;
}
</style>
