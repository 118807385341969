<template>
  <ContentContainer :class="$style['product-recipes']">
    <h1 :class="$style['product-recipes__title']">
      {{ $t('recipes.title') }}
    </h1>
    <RecipeSwiper
      :class="$style['product-recipes__swiper']"
      :items="uniqueRecipes"
      @select-recipe="onSelectRecipe"
    />
    <SimpleFadeTransition>
      <RecipeOverlay
        @close-overlay="recipeOverlayOpen = false"
        v-if="recipeOverlayOpen"
        :recipe="selectedRecipe"
        :color="selectedRecipeIndex % 2 === 0 ? 'primary' : 'secondary'"
      />
    </SimpleFadeTransition>
  </ContentContainer>
</template>

<script>
import productSectionBaseMixin from '@/views/Product/sections/productSectionBaseMixin';
import RecipeSwiper from '@/components/recipes/RecipeSwiper';
import uniqBy from '@/utils/uniqBy';
import RecipeOverlay from '@/components/recipes/RecipeOverlay';

export default {
  name: 'ProductRecipes',
  components: {
    RecipeSwiper,
    RecipeOverlay
  },
  mixins: [productSectionBaseMixin],
  data() {
    return {
      recipeOverlayOpen: false,
      selectedRecipeIndex: 0
    };
  },
  methods: {
    uniqBy,
    onSelectRecipe(item) {
      this.selectedRecipe = item;
      this.recipeOverlayOpen = true;
    }
  },
  computed: {
    uniqueRecipes() {
      return uniqBy(
        this.product.recipes.concat(this.product.productCategory.recipes),
        item => item.id
      );
    },
    selectedRecipe: {
      get() {
        return this.uniqueRecipes[this.selectedRecipeIndex];
      },
      set(val) {
        this.selectedRecipeIndex = this.uniqueRecipes.findIndex(
          recipe => recipe.id === val.id
        );
      }
    }
  }
};
</script>

<style module>
.product-recipes {
  @apply bg-black;
}

.product-recipes__title {
  @apply text-white text-4xl mb-12 font-bold;
}

.product-recipes__swiper {
  @apply max-w-3xl w-full m-auto;
}
</style>
