<template>
  <Button :type="type" :text="file.name" :link="computedLink" target="_blank" />
</template>

<script>
import buttonTypeMixin from '@/components/button/buttonTypeMixin';
import File from '@/models/File';
import concatUrl from '@/utils/concatUrl';

export default {
  name: 'MediaFileDownload',
  mixins: [buttonTypeMixin],
  props: {
    file: {
      type: File,
      required: true
    },
    strapi: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedLink() {
      if (this.strapi) {
        return concatUrl(process.env.VUE_APP_STRAPI_URL, this.file.file.url);
      } else {
        return this.file.file.url;
      }
    }
  }
};
</script>
