import BaseModel from '@/models/BaseModel';
import UploadFile from '@/models/UploadFile';

export default class File extends BaseModel {
  /**
   * @returns {String}
   */
  get name() {
    return this._object.name;
  }

  /**
   * @returns {UploadFile}
   */
  get file() {
    return this.objectGetter('file', this._object.file, UploadFile);
  }
}
