import BaseModel from '@/models/BaseModel';

export default class UploadFile extends BaseModel {
  /**
   * @returns {String}
   */
  get name() {
    return this._object.name;
  }
  /**
   * @returns {String}
   */
  get url() {
    return this._object.url;
  }
  /**
   * @returns {String}
   */
  get mime() {
    return this._object.mime;
  }
}
