import recipeQueryPartial from '@/queries/recipeQueryPartial';

export default i18n => {
  return `
      name_${i18n.locale}
      recipes {
        ${recipeQueryPartial(i18n)}
      }
      id
  `;
};
