<template>
  <div :class="$style['media-galleries']">
    <button
      :class="{
        [$style['media-galleries__button']]: true,
        [$style['media-galleries__button--disabled']]: images.length === 0
      }"
      @click="openImageGallery"
    >
      <img
        :class="$style['media-galleries__button-image']"
        src="../../../assets/img/media/media__image-icon.svg"
        alt=""
      />
    </button>
    <button
      :class="{
        [$style['media-galleries__button']]: true,
        [$style['media-galleries__button--disabled']]: videos.length === 0
      }"
      @click="openVideoGallery"
    >
      <img
        :class="$style['media-galleries__button-image']"
        src="../../../assets/img/media/media__video-icon.svg"
        alt=""
      />
    </button>
    <SimpleFadeTransition>
      <ImageGalleryOverlay
        v-if="imageGalleryOpen"
        :items="images"
        @close-overlay="closeImageGallery"
      />
    </SimpleFadeTransition>
    <SimpleFadeTransition>
      <VideoGalleryOverlay
        v-if="videoGalleryOpen"
        :items="videos"
        @close-overlay="closeVideoGallery"
      />
    </SimpleFadeTransition>
  </div>
</template>

<script>
export default {
  name: 'MediaGalleries',
  data() {
    return {
      imageGalleryOpen: false,
      videoGalleryOpen: false
    };
  },
  components: {
    ImageGalleryOverlay: () =>
      import(
        /* webpackChunkName: "image-gallery-overlay" */ '@/components/media/gallery/ImageGalleryOverlay'
      ),
    VideoGalleryOverlay: () =>
      import(
        /* webpackChunkName: "video-gallery-overlay" */ '@/components/media/gallery/VideoGalleryOverlay'
      )
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    videos: {
      type: Array,
      required: true
    }
  },
  methods: {
    openImageGallery() {
      if (this.images.length > 0) {
        this.imageGalleryOpen = true;
      }
    },
    closeImageGallery() {
      this.imageGalleryOpen = false;
    },
    openVideoGallery() {
      if (this.images.length > 0) {
        this.videoGalleryOpen = true;
      }
    },
    closeVideoGallery() {
      this.videoGalleryOpen = false;
    }
  }
};
</script>

<style module>
.media-galleries {
  @apply flex justify-center items-center;
}

.media-galleries__button {
  @apply border-primary border
  py-2 px-8
  bg-black;
}

.media-galleries__button--disabled {
  @apply opacity-50;
}

.media-galleries__button:first-child {
  @apply rounded-tl-3xl rounded-bl-3xl;
}

.media-galleries__button:last-child {
  @apply rounded-tr-3xl rounded-br-3xl;
}

.media-galleries__button-image {
  @apply h-8;
}
</style>
