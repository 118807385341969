<template>
  <Splide
    class="recipe-swiper"
    :class="$style['recipe-swiper']"
    :options="options"
  >
    <SplideSlide
      :class="$style['recipe-swiper__slide']"
      v-for="item in items"
      :key="item.id"
    >
      <MediaBgImage
        :class="$style['recipe-swiper__slide-image']"
        :strapi="true"
        :src="item.image.url"
      />
      <div :class="$style['recipe-swiper__slide-content']">
        <div :class="$style['recipe-swiper__slide-content-title']">
          {{ item.title }}
        </div>
        <PlusButton
          :class="$style['recipe-swiper__slide-content-open']"
          @click.native="selectRecipe(item)"
        />
      </div>
    </SplideSlide>
  </Splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Recipe from '@/models/Recipe';
import MediaBgImage from '@/components/media/MediaBgImage';
import PlusButton from '@/components/button/PlusButton';

export default {
  name: 'RecipeSwiper',
  components: {
    Splide,
    SplideSlide,
    MediaBgImage,
    PlusButton
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: prop =>
        prop.length > 0 && prop.every(item => item instanceof Recipe)
    }
  },
  data() {
    return {
      options: {
        arrows: false,
        pagination: false,
        perPage: 1,
        rewind: true,
        gap: '3%',
        fixedWidth: '80%',
        trimSpace: false,
        focus: 'center'
      }
    };
  },
  methods: {
    selectRecipe(item) {
      this.$emit('select-recipe', item);
    }
  }
};
</script>

<style>
.recipe-swiper .splide__track {
  @apply overflow-visible;
}
</style>

<style module>
.recipe-swiper {
  @apply w-full;
}

.recipe-swiper .splide__track {
  @apply overflow-visible;
}

.recipe-swiper__slide-image {
  @apply h-56 border rounded-tr-2xl rounded-tl-3xl border-b-0;
}

.recipe-swiper__slide-content {
  @apply bg-primary
  border-none rounded-br-2xl rounded-bl-2xl border-t-0
  p-4 pb-8
  relative
  overflow-hidden;
}

.recipe-swiper__slide:nth-child(even) .recipe-swiper__slide-content {
  @apply bg-secondary;
}

.recipe-swiper__slide-content-title {
  @apply text-white text-xl font-bold w-3/4;
}

.recipe-swiper__slide-content-open {
  @apply absolute bottom-3 right-3;
}
</style>
