<template>
  <Button :type="buttonTypes.OPACITY" :class="$style['minus-button']">
    <img src="../../assets/img/global/global__minus.svg" alt="" />
  </Button>
</template>

<script>
export default {
  name: 'MinusButton'
};
</script>

<style module>
.minus-button {
  @apply w-10 h-10
  flex items-center justify-center
  p-0
  inline-block
  text-2xl leading-none;
}
</style>
