<template>
  <Button :type="buttonTypes.OPACITY" :class="$style['plus-button']">
    <img src="../../assets/img/global/global__plus.svg" alt="" />
  </Button>
</template>

<script>
export default {
  name: 'PlusButton'
};
</script>

<style module>
.plus-button {
  @apply w-10 h-10
  flex items-center justify-center
  p-0
  inline-block
  text-2xl leading-none;
}
</style>
