export default class BaseModel {
  /**
   * @param {Object} object
   * @param {String} [locale]
   */
  constructor(object, locale) {
    // if null is the value of an inner object,
    // should not throw errors when calling a getter on it
    // instead, should return undefined
    this._object = object != null ? object : {};
    this._locale = locale;
  }

  /**
   * @param {String} name
   * @param {Object} object
   * @param {Function} modelClass - model class constructor
   * @returns {*}
   */
  objectGetter(name, object, modelClass) {
    if (!this[`_${name}`]) {
      if (Array.isArray(object)) {
        this[`_${name}`] = object.map(
          item => new modelClass(item, this._locale)
        );
      } else {
        this[`_${name}`] = new modelClass(object, this._locale);
      }
    }
    return this[`_${name}`];
  }

  /**
   * @param {String} propertyName
   * @returns {*}
   */
  localizedGetter(propertyName) {
    if (!this._locale) {
      throw new Error('_locale is undefined on the instance');
    }
    return this._object[`${propertyName}_${this._locale}`];
  }

  /**
   * @returns {String|Number}
   */
  get id() {
    return this._object.id;
  }
}
