import BaseModel from '@/models/BaseModel';
import ProductCategory from '@/models/ProductCategory';
import Recipe from '@/models/Recipe';
import File from '@/models/File';
import UploadFile from '@/models/UploadFile';

export default class Product extends BaseModel {
  /**
   * @returns {String}
   */
  get title() {
    return this.localizedGetter('title');
  }
  /**
   * @returns {String}
   */
  get description() {
    return this.localizedGetter('description');
  }
  /**
   * @returns {ProductCategory}
   */
  get productCategory() {
    return this.objectGetter(
      'productCategory',
      this._object.product_category,
      ProductCategory
    );
  }
  /**
   * @returns {Array<Recipe>}
   */
  get recipes() {
    return this.objectGetter('recipes', this._object.recipes, Recipe);
  }
  /**
   * @returns {Array<File>}
   */
  get files() {
    return this.objectGetter('files', this.localizedGetter('files'), File);
  }
  /**
   * @returns {UploadFile}
   */
  get featuredImage() {
    return this.objectGetter(
      'featuredImage',
      this._object.featured_image,
      UploadFile
    );
  }
  /**
   * @returns {Array<UploadFile>}
   */
  get videos() {
    return this.objectGetter('videos', this._object.videos, UploadFile);
  }
  /**
   * @returns {Array<UploadFile>}
   */
  get images() {
    return this.objectGetter('images', this._object.images, UploadFile);
  }
}
