import productCategoryQueryPartial from '@/queries/productCategoryQueryPartial';
import fileQueryPartial from '@/queries/fileQueryPartial';
import uploadFileQueryPartial from '@/queries/uploadFileQueryPartial';
import recipeQueryPartial from '@/queries/recipeQueryPartial';

export default (productNumber, i18n) => {
  const lang = i18n.locale;
  return `{
      products(where: { product_number: ${productNumber} }) {
          product_category {
              ${productCategoryQueryPartial(i18n)}
          }
          recipes {
            ${recipeQueryPartial(i18n)}
          }
          title_${lang}
          description_${lang}
          files_${lang} {
            ${fileQueryPartial}
          }
          featured_image {
            ${uploadFileQueryPartial}
          }
          images {
            ${uploadFileQueryPartial}
          }
          videos {
            ${uploadFileQueryPartial}
          }
          id
      }
  }`;
};
